import { createApp } from 'vue'
import router from './router';
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from "@/auth";

import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

export const app = createApp(App);

app .use(router)
    .use(PerfectScrollbar)
    .use(VueAxios, axios)
    .use(auth);

app.mount('#app');
