export default {
  name: "formLogin",
  data() {
    return {
      loading: false,
      formData: {
        email: "",
        password: ""
      }
    };
  },
  mounted() {
    let timeout = setInterval(() => {
      if (this.$auth.ready()) {
        clearInterval(timeout);
        // alert('Ready');
      }
    });
  },

  methods: {
    login() {
      let redirect = this.$auth.redirect();
      let app = this;
      this.loading = true;
      this.$auth.login({
        data: {
          email: this.formData.email,
          password: this.formData.password
        },
        rememberMe: true,
        fetchUser: true,
        staySignedIn: true
      }).then(res => {
        app.has_error = false;
        this.loading = false;

        //this.$router.push({name: 'dashboard'});
      }, res => {
        this.loading = false;
        //this.$toast.error(this.$t('login.error'));
      });
    }
  }
};