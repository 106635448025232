import axios from "axios";
import MenuSidebar from "@/components/menuSidebar";
import formLogin from "@/pages/auth/formLogin.vue";
import MenuTopbar from "@/components/menuTopbar.vue";
export default {
  name: 'App',
  components: {
    MenuTopbar,
    formLogin,
    MenuSidebar
  },
  data() {
    return {
      debug: false,
      config: {
        url: "https://sama.dev.bitdivision.de/api/",
        debug: false,
        staging: false
      }
    };
  },
  mounted() {
    axios.defaults.baseURL = this.config.url;
    axios.defaults.params = {
      'lang': "de"
    };
  }
};