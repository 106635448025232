import {createRouter, createWebHistory} from 'vue-router';

function loadView(view) {
    return () => import(`@/pages/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: loadView("home/dashboardAdmin"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/article/:id',
            name: 'article',
            component: loadView("article/articleDetail"),
            meta: {
                auth: true,
                tab: false
            }
        },
        {
            path: '/login',
            name: 'login',
            component: loadView("auth/formLogin"),
            meta: {
                auth: false,
                tab: false
            }
        },
    ]
});

export default (app) => {
    app.router = router;
    app.use(router);
}