import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "appwrapper",
  class: "h-100"
};
const _hoisted_2 = {
  style: {
    "position": "absolute",
    "bottom": "0",
    "left": "0",
    "right": "0",
    "top": "57px"
  }
};
const _hoisted_3 = {
  class: "d-flex flex-nowrap h-100",
  style: {
    "min-height": "0px"
  }
};
const _hoisted_4 = {
  class: "flex-fill"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_form_login = _resolveComponent("form-login");
  const _component_menu_topbar = _resolveComponent("menu-topbar");
  const _component_menu_sidebar = _resolveComponent("menu-sidebar");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("main", _hoisted_1, [_createVNode(_Transition, null, {
    default: _withCtx(() => [!_ctx.$auth.check() ? (_openBlock(), _createBlock(_component_form_login, {
      key: 0
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_menu_topbar), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_menu_sidebar), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view)])])])]);
}